





































































































import { Vue, Component } from "vue-property-decorator";
import IFCViewer from "@/components/IFCViewer.vue";

@Component({
  components: {
    IFCViewer
  }
})
export default class Home extends Vue {}
