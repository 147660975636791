import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import { api } from "@/api";
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import { UserProfile } from "@/interfaces";
import router from "@/router";
import { State } from "@/store/state";

Vue.use(Vuex);

const defaultState: State = {
  token: "",
  isLoggedIn: null,
  logInError: false,
  userProfile: null
};

type Context = ActionContext<State, State>;

export default new Vuex.Store<State>({
  state: defaultState,
  mutations: {
    setToken(state: State, payload: string) {
      state.token = payload;
    },
    setLoggedIn(state: State, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setLogInError(state: State, payload: boolean) {
      state.logInError = payload;
    },
    setUserProfile(state: State, payload: UserProfile) {
      state.userProfile = payload;
    }
  },
  getters: {
    hasAdminAccess: (state: State) => {
      return (
        state.userProfile &&
        state.userProfile.isSuperuser &&
        state.userProfile.isActive
      );
    }
  },
  actions: {
    async logIn(
      context: Context,
      payload: { username: string; password: string }
    ) {
      try {
        const response = await api.logInGetToken(
          payload.username,
          payload.password
        );
        const token = response.data.access_token;
        if (token) {
          saveLocalToken(token);
          context.commit("setToken", token);
          context.commit("setLoggedIn", true);
          await context.dispatch("getUserProfile");
          context.dispatch("routeLoggedIn");
        } else {
          await context.dispatch("logOut");
        }
      } catch (err) {
        context.commit("setLogInError", true);
        await context.dispatch("logOut");
      }
    },
    async logOut(context: Context) {
      await context.dispatch("removeLogIn");
      await context.dispatch("routeLoggedOut");
    },
    async getUserProfile(context: Context) {
      try {
        const response = await api.getMe(context.state.token);
        if (response.data) {
          context.commit("setUserProfile", response.data);
        }
      } catch (err) {
        context.dispatch("logOut");
      }
    },
    async checkLoggedIn(context: Context) {
      if (!context.state.isLoggedIn) {
        let token = context.state.token;
        if (!token) {
          const localToken = getLocalToken();
          if (localToken) {
            context.commit("setToken", localToken);
            token = localToken;
          }
        }

        if (token) {
          try {
            const response = await api.getMe(token);
            context.commit("setLoggedIn", true);
            context.commit("setUserProfile", response.data);
          } catch (err) {
            await context.dispatch("removeLogIn");
          }
        } else {
          await context.dispatch("removeLogIn");
        }
      }
    },
    async removeLogIn(context: Context) {
      removeLocalToken();
      context.commit("setToken", "");
      context.commit("setLoggedIn", false);
    },
    routeLoggedIn() {
      if (router.currentRoute.path === "/login") {
        router.push("/");
      }
    },
    routeLoggedOut() {
      if (router.currentRoute.path !== "/login") {
        router.push("/login");
      }
    }
  },
  modules: {}
});
