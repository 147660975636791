import axios from "axios";
import { apiUrl } from "@/env";
import {
  Vote,
  UserProfile,
  UserProfileUpdate,
  UserProfileCreate,
  ModelClassesRequest,
  ModelDataRequest,
  ClassInfoRequest,
  ModelData
} from "@/interfaces";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<UserProfile>(`${apiUrl}/api/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: UserProfileUpdate) {
    return axios.put<UserProfile>(
      `${apiUrl}/api/users/me`,
      data,
      authHeaders(token)
    );
  },
  async getUsers(token: string) {
    return axios.get<UserProfile[]>(`${apiUrl}/api/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: UserProfileUpdate) {
    return axios.put(`${apiUrl}/api/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: UserProfileCreate) {
    return axios.post(`${apiUrl}/api/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/reset-password/`, {
      newPassword: password,
      token
    });
  },
  async getModelClasses(data: ModelClassesRequest) {
    return axios.get<Record<string, number>>(
      `${apiUrl}/api/models/meta/classes?confirmed=${data.confirmed}`
    );
  },
  async getMappableClasses() {
    return axios.get<string[]>(`${apiUrl}/api/models/meta/mappable`);
  },
  async getModelData(data: ModelDataRequest) {
    return axios.get<ModelData>(`${apiUrl}/api/models/${data.publicName}`);
  },
  async getClassInfo(data: ClassInfoRequest) {
    return axios.get<string[]>(
      `${apiUrl}/api/models/meta/${data.ifcClass}?confirmed=${data.confirmed}`
    );
  },
  async sendVote(token: string, data: Vote) {
    return axios.post(`${apiUrl}/api/votes`, data, authHeaders(token));
  },
  async markForDeletion(token: string, data: Vote) {
    return axios.delete(
      `${apiUrl}/api/models/${data.modelId}`,
      authHeaders(token)
    );
  }
};
