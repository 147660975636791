export class Vote {
  modelId?: string;
  originalClass?: string;
  votedClass?: string;

  constructor(modelId?: string, originalClass?: string, votedClass?: string) {
    this.modelId = modelId;
    this.originalClass = originalClass;
    this.votedClass = votedClass;
  }
}

export interface KeyBinding {
  key: string;
  type: string;
}

export interface IfcPropertySet {
  name: string;
  description?: string;
  properties: Array<object>;
}

export interface ModelData {
  confirmed: boolean;
  originalIfcClass: string;
  confirmedIfcClass?: string;
  createdOn: Date;
  confirmedOn?: Date;
  publicName: string;
  propertySets: Array<IfcPropertySet>;
  xktData: string;
  xktMeta: object;
}

export interface ModelClassesRequest {
  confirmed: boolean;
}

export interface ModelDataRequest {
  publicName: string;
}

export interface ClassInfoRequest {
  ifcClass: string;
  confirmed: boolean;
}

export interface UserProfile {
  email: string;
  isActive: boolean;
  isSuperuser: boolean;
  role: string;
  fullName: string;
}

export interface UserProfileUpdate {
  email?: string;
  fullName?: string;
  role?: string;
  password?: string;
  isActive?: boolean;
  isSuperuser?: boolean;
}

export interface UserProfileCreate {
  email: string;
  fullName?: string;
  role?: string;
  password?: string;
  isActive?: boolean;
  isSuperuser?: boolean;
}
