











































































import { Vue, Component } from "vue-property-decorator";

@Component
export default class App extends Vue {
  drawer = false;

  get isLoggedIn() {
    return this.$store.state.isLoggedIn;
  }

  get user() {
    return this.$store.state.userProfile;
  }
}
