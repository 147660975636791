



































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Vote, KeyBinding, ModelData } from "@/interfaces";
import { Viewer } from "@xeokit/xeokit-sdk/src/viewer/Viewer.js";
import { XKTLoaderPlugin } from "@xeokit/xeokit-sdk/src/plugins/XKTLoaderPlugin/XKTLoaderPlugin.js";
import { api } from "@/api";

type KeyHandlers = Array<(e: KeyboardEvent) => void>;

@Component
export default class IFCViewer extends Vue {
  viewer: any = null;
  xktLoader: any = null;
  model: any = null;
  modelData: ModelData | null = null;
  classes: Record<string, number> = {};
  currentClassInfo: Array<string> = [];
  currentClassName = "";
  currentModelIndex = -1;
  loading = false;
  customKeyHandlers: KeyHandlers = [];
  conflictOccurred = false;
  vote: Vote = new Vote();

  @Prop({ default: () => [] }) mappableIfcClasses!: Array<string>;
  @Prop({ default: false }) loadConfirmedModels!: boolean;
  @Prop({ default: () => [] }) keyBindings!: Array<KeyBinding>;

  mounted() {
    this.viewer = new Viewer({
      canvasId: "model-canvas"
    });

    this.xktLoader = new XKTLoaderPlugin(this.viewer);
    this.getClasses();
  }

  beforeDestroy() {
    this.removeCustomHotkeys();
  }

  get currentClassLength() {
    if (this.classes[this.currentClassName] === undefined) return 0;
    return this.classes[this.currentClassName];
  }

  @Watch("currentClassName")
  async onCurrentClassNameChanged(newValue: string) {
    if (newValue === "") {
      this.currentModelIndex = -1;
      this.currentClassInfo = [];
      this.modelData = null;
      this.deleteModel();
      await this.getClasses();
    } else {
      this.currentModelIndex = 0;
      await this.getClassInfo();
      await this.setModel();
    }
    this.resetVote();
  }

  @Watch("keyBindings", { deep: true })
  onKeyBindingsChanged(newValue: Array<KeyBinding>) {
    this.removeCustomHotkeys(); // Remove all previous hotkeys
    this.registerCustomHotkeys(newValue);
  }

  focusViewer() {
    const el = document.getElementById("ifcviewer");
    if (el === null) return;

    el.focus();
  }

  async getClasses() {
    let response = null;
    const spinner = this.viewer.scene.canvas.spinner;

    try {
      spinner.processes++;
      response = await api.getModelClasses({
        confirmed: this.loadConfirmedModels
      });
    } catch (err) {
      this.currentClassName = "";
      spinner.processes--;
      return;
    }

    if (response.data) {
      this.classes = response.data;
    }
    spinner.processes--;
  }

  async getClassInfo() {
    if (this.currentClassName === "") return;
    const spinner = this.viewer.scene.canvas.spinner;
    this.loading = true;
    spinner.processes++;
    let response = null;

    try {
      response = await api.getClassInfo({
        ifcClass: this.currentClassName,
        confirmed: this.loadConfirmedModels
      });
    } catch (err) {
      this.currentClassName = "";
      this.loading = false;
      spinner.processes--;
      return;
    }

    if (response.data) {
      this.currentClassInfo = response.data;
      this.classes[this.currentClassName] = this.currentClassInfo.length;
    }
    this.loading = false;
    spinner.processes--;
  }

  deleteModel() {
    if (this.model !== null) {
      this.model.destroy();
    }
  }

  async setModel() {
    this.deleteModel();
    if (this.loading) return;
    if (this.currentClassName === "") return;
    if (this.currentClassLength === 0) return;
    if (this.currentClassInfo === null) return;

    this.loading = true;

    const modelId = this.currentClassInfo[this.currentModelIndex];
    const payload = {
      publicName: modelId
    };

    let response = null;
    try {
      response = await api.getModelData(payload);
    } catch (err) {
      this.currentClassName = "";
      this.loading = false;
      return;
    }

    this.modelData = response.data;

    this.model = this.xktLoader.load({
      id: "myModel",
      xkt: this._base64ToArrayBuffer(this.modelData.xktData),
      metaModelData: this.modelData.xktMeta
    });

    this.model.on("loaded", () => {
      this.viewer.cameraFlight.jumpTo(this.model);
      this.loading = false;
    });
  }

  async confirmModel() {
    if (this.currentClassName === "") return;
    if (this.loadConfirmedModels) return;
    if (this.loading) return;
    this.loading = true;

    try {
      await api.sendVote(this.$store.state.token, this.vote);
    } catch (err) {
      if (err.response.status === 400) {
        console.log("Reloading");
        this.conflictOccurred = true;
        setTimeout(() => (this.conflictOccurred = false), 2000);
        // Model has already been confirmed by someone else. Reload classes.
        await this.getClasses();
        await this.getClassInfo();
        this.loading = false;
        if (this.currentClassName === "") return;

        this.currentModelIndex =
          this.currentModelIndex % this.currentClassLength;
        this.setModel();
        this.resetVote();
      } else {
        this.loading = false;
      }
      return;
    }

    this.loading = false;
    this.classes[this.currentClassName]--;
    this.currentClassInfo.splice(this.currentModelIndex, 1);

    if (this.currentClassLength === 0) {
      this.currentClassName = "";
    } else {
      this.currentModelIndex = this.currentModelIndex % this.currentClassLength;
      this.setModel();
      this.resetVote();
    }
    this.$emit("confirm");
  }

  async markModelForDeletion() {
    if (this.currentClassName === "") return;
    if (this.loadConfirmedModels) return;
    if (this.loading) return;

    this.vote.votedClass = "SpecialClass_Delete";
    await this.confirmModel();
  }

  async nextModel() {
    if (this.currentClassName === "") return;
    if (this.loading) return;
    this.currentModelIndex =
      (this.currentModelIndex + 1) % this.currentClassLength;

    await this.setModel();
    this.resetVote();
  }

  async previousModel() {
    if (this.currentClassName === "") return;
    if (this.loading) return;
    this.currentModelIndex =
      (this.currentModelIndex + this.currentClassLength - 1) %
      this.currentClassLength;

    await this.setModel();
    this.resetVote();
  }

  resetVote() {
    if (this.currentClassName === "") {
      this.vote = new Vote();
    } else {
      this.vote = {
        modelId: this.currentClassInfo[this.currentModelIndex],
        originalClass: this.currentClassName,
        votedClass: this.currentClassName
      };
    }
  }

  registerCustomHotkeys(keys: Array<KeyBinding>) {
    const el = document.getElementById("ifcviewer");
    if (el === null) return;

    for (const k of keys) {
      const handler = (e: KeyboardEvent) => {
        if (e.key === k.key) {
          e.preventDefault();
          this.vote.votedClass = k.type;
        }
      };
      this.customKeyHandlers.push(handler);
      el.addEventListener("keydown", handler);
    }
  }

  removeCustomHotkeys() {
    const el = document.getElementById("ifcviewer");
    if (el === null) return;

    for (const handler of this.customKeyHandlers) {
      el.removeEventListener("keydown", handler);
    }

    this.customKeyHandlers = [];
  }

  _base64ToArrayBuffer(base64: string) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
