import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

async function loginGuard(
  to: Route,
  from: Route,
  next: (route?: string) => void
) {
  await store.dispatch("checkLoggedIn");
  if (store.state.isLoggedIn) {
    if (to.path === "/login") {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
  next();
}

async function checkLoggedIn(
  to: Route,
  from: Route,
  next: (route?: string) => void
) {
  await store.dispatch("checkLoggedIn");
  next();
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => checkLoggedIn(to, from, next)
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("../views/Login.vue"),
    beforeEnter: (to, from, next) => loginGuard(to, from, next)
  },
  {
    path: "/contribute",
    name: "Contribute",
    component: () =>
      import("../views/Contribute.vue"),
    beforeEnter: (to, from, next) => loginGuard(to, from, next)
  },
  {
    path: "/users/me",
    component: () =>
      import("../views/Dashboard.vue"),
    beforeEnter: (to, from, next) => loginGuard(to, from, next)
  },
  {
    path: "/refinement",
    name: "Refinement",
    component: () =>
      import("../views/Refinement.vue"),
    beforeEnter: (to, from, next) => loginGuard(to, from, next)
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
